@import "./global.css";

.word {
  align-self: baseline;
  color: var(--text);
  margin-bottom: 18px;
  margin-right: 8px;
  font-size: 18px;
}

.word.scholar {
  color: var(--scholar_faint);
}

.word.challenger {
  color: var(--challenger_faint);
}

.word.input {
  margin-right: 2px;
}

@media (min-width: 768px) {
  .word {
    font-size: 36px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
}
