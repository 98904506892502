@import "./global.css";

.container {
  display: flex;
  flex-direction: column;
}

.divider {
  font-size: 32px;
  color: var(--icon);
  font-weight: 700;
  display: none;
  margin: 0 22px;
}

@media (min-width: 768px) {
  .divider {
    display: block;
  }
  .container {
    display: flex;
    flex-direction: row;
  }
}
