@import "./global.css";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.score {
  font-size: 14px;
  margin-bottom: 3px;
  color: var(--text);
  text-align: left;
}

.label {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
}

.challenger {
  color: var(--challenger);
}

.scholar {
  color: var(--scholar);
}

@media (min-width: 768px) {
  .score {
    font-size: 48px;
    margin-bottom: 8px;
  }
  .label {
    font-size: 24px;
  }
}
