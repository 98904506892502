@import "./global.css";

.tile {
  padding: 32px 25px;
  color: var(--text);
  font-size: 18px;
  cursor: pointer;
}
.tile.disabled {
  cursor: default;
  color: var(--icon);
}
.tile:hover {
  background: var(--tile_hover);
}

.tile.disabled:hover {
  background: none;
}

@media (min-width: 768px) {
  .tile {
    font-size: 24px;
    padding: 32px 44px;
  }
}
