@import "./global.css";

.container {
  z-index: 1;
  background: var(--bg);
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  box-shadow: var(--shadow);
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .container {
    align-items: flex-start;
    box-shadow: none;
    padding: 43px 48px;
  }
}
