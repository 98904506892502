@import "./global.css";

.page {
  min-height: 100vh;
  display: grid;
  box-sizing: border-box;
  max-width: 100vw;
  grid-template:
    ". titleA ." auto
    ". single_verseA ." auto
    ". . ." 60px
    ". passageA . " auto
    ". . ." 60px / 1fr 200px 1fr;
}

.title {
  grid-area: titleA;
  color: var(--primary);
  font-weight: 700;
  text-align: center;
  font-size: 32px;
  margin-top: 70px;
  margin-bottom: 83px;
}

.option {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  color: var(--text);
  font-size: 18px;
  width: 200px;
  text-align: center;
}

.illustration {
  margin-top: 19px;
  margin-bottom: 44px;
  height: 172px;
  width: 200px;
}

.single_verse {
  grid-area: single_verseA;
}

.passage {
  grid-area: passageA;
}

@media (min-width: 768px) {
  .title {
    margin: 0;
  }
  .page {
    grid-template:
      ". . . . ." 120px
      "titleA titleA titleA titleA titleA" auto
      ". . . . ." 78px
      ". single_verseA . passageA ." auto
      ". . . . ." 1fr / 1fr 200px 168px 200px 1fr;
  }
}
