@import "./global.css";

.cursor {
  align-self: baseline;
  width: 2px;
  background: var(--text);
  animation: fade 0.8s linear infinite;
}
.cursor.visible {
  height: 15px;
}
.curosr.invisible {
  height: 0px;
}
.cursor.error {
  background: var(--error);
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .cursor {
    width: 3px;
  }

  .cursor.visible {
    height: 24px;
  }
}
