@import "./global.css";

.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg);
}
.loader {
  border: 0.2rem solid var(--primary_hover);
  border-top: 0.2rem solid var(--primary);
  border-radius: 50%;
  width: 2.875rem;
  height: 2.875rem;
  box-sizing: border-box;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
