@import "./global.css";

.page {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.close_button {
  position: absolute;
  left: 16px;
  top: 14px;
}

.button_group {
  width: 200px;
}

.sd {
  margin-bottom: 24px;
  width: 100%;
}

.md {
  width: 100%;
  margin-bottom: 51px;
  display: none;
}

@media (min-width: 768px) {
  .sd {
    display: none;
  }
  .md {
    display: block;
  }
  .close_button {
    left: 48px;
    top: 43px;
  }

  .button_group {
    width: 330px;
  }
}
