@import "./global.css";

.marker {
  align-self: baseline;
  font-size: 12px;
  color: var(--primary);
  font-weight: 700;
  margin-bottom: 18px;
  margin-right: 8px;
}

@media (min-width: 768px) {
  .marker {
    font-size: 18px;
    margin-right: 10px;
    font-size: 18px;
    margin-bottom: 20px;
  }
}
