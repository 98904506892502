@import "./global.css";

.page {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0px 25px 60px 25px;
}

.close_button {
  margin-top: 22px;
  margin-bottom: 33px;
}

.title {
  font-size: 32px;
  color: var(--primary);
  font-weight: 700;
  margin-bottom: 46px;
}

.box_area {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 44px);
  grid-auto-flow: row;
  column-gap: 25px;
  row-gap: 25px;
}

@media (min-width: 768px) {
  .box_area {
    grid-template-columns: repeat(auto-fill, 64px);
    column-gap: 32px;
    row-gap: 32px;
  }
  .page {
    padding: 0px 44px 60px 44px;
  }
  .close_button {
    margin-top: 45px;
    margin-bottom: 41px;
  }
  .title {
    font-size: 44px;
    margin-bottom: 74px;
  }
}
